import React, { useState } from "react";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const SeedAcquisitionForm = () => {
  const [formData, setFormData] = useState({
    seed_name: "",
    acquisition_date: "",
    species_name: "",
    origin_country: "",
    origin_state: "",
    seed_quantity: "",
    climate_grown: "",
    fertilizers_used: "",
    soil_type: "",
    sowing: "",
    grid_no: "",
    sowed_soil: "Cocopeat",
    soil_water: "",
    seed_damage: "",
    image_file_name: "",
    seed_no: "", // Added seed_no to formData
  });

  // Define species options for each seed name
  const seedToSpeciesMap = {
    Rice: ["Oryza Sativa", "Oryza Rufipogon"],
    Tomato: ["Solanum pimpinellifolium", "Solanum lycopersicum"],
    Sorghum: ["Legumes"],
    Groundnut: ["Legumes"],
    Pigeonpea: ["Legumes"],
    Chickpea: ["Legumes"],
    Millet: ["Millet"],
  };

  const [showSowingFields, setShowSowingFields] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false); // Added loading state
  const [additionalImages, setAdditionalImages] = useState([]); // For additional image uploads
  const [allowAdditionalUploads, setAllowAdditionalUploads] = useState(false); // For toggling the upload option

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenDialog = () => setOpenDialog(true);

  const handleCloseDialog = () => setOpenDialog(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  // Function to handle the file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
      setFormData((prevData) => ({
        ...prevData,
        image_file_name: file.name,
        image_file: file, // Store the file object
      }));
    }
  };

  const generateSeedNumber = () => {
    if (
      formData.seed_name &&
      formData.species_name &&
      formData.origin_country &&
      formData.origin_state
    ) {
      const randomString = Math.floor(Math.random() * 1000000).toString();
      const seedNum =
        formData.seed_name.substring(0, 2) +
        formData.species_name.substring(0, 2) +
        formData.origin_country.substring(0, 2) +
        formData.origin_state.substring(0, 2) +
        randomString;

      setFormData((prevData) => ({
        ...prevData,
        seed_no: seedNum.toUpperCase(),
      }));
    } else {
      alert(
        "Please fill out all required fields before generating the seed number."
      );
    }
  };

  const handleSowingChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      sowing: value,
    }));
    setShowSowingFields(value === "Yes");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when submitting
    const apiUrl =
      "https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/dataCollection";
    const apiKey = "vRtW4LOFB61VjXvcvRtKu6QOItTs7b7w4d2QoI2n";
    let success = false;
  
    try {
      // Primary image upload
      if (formData.image_file) {
        const imageApiUrl = `https://mexqi5uelf.execute-api.ap-south-1.amazonaws.com/dev/digi-s3-bucket%2fSeedAcquisition_images%2f${encodeURIComponent(
          formData.seed_name
        )}/${encodeURIComponent(formData.image_file.name)}`;
  
        const imageUploadResponse = await fetch(imageApiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": formData.image_file.type,
            "x-api-key": apiKey,
          },
          body: formData.image_file,
        });
  
        if (!imageUploadResponse.ok) throw new Error("Primary image upload failed");
      }
  
      // Upload additional images if user allowed it
      if (allowAdditionalUploads) {
        await uploadAdditionalImages();
      }
  
      // Submit the form data to the first API
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          image_file_name: formData.image_file?.name,
        }),
      });
  
      success = response.ok;
  
      // If sowing is "Yes," send data to the germination table API
      if (formData.sowing === "Yes") {
        const germinationApiUrl =
          "https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/seedGermination";
  
        const germinationData = {
          seed_no: formData.seed_no,
          seed_name: formData.seed_name,
          grid_no: formData.grid_no,
          seed_damage: formData.seed_damage || "Not Damaged",
          soil_ph: formData.soil_ph || "6.5",
          sowed_soil: formData.sowed_soil,
          sprouted_date: new Date().toISOString().split("T")[0],
        };
  
        const germinationResponse = await fetch(germinationApiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(germinationData),
        });
  
        if (!germinationResponse.ok) throw new Error("Germination data submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Reset loading state after submission
      alert(
        success
          ? "Form data and images uploaded successfully."
          : "Failed to submit form data or upload images."
      );
    }
  };
  

  const clearForm = () => {
    setFormData({
      seed_name: "",
      acquisition_date: "",
      species_name: "",
      origin_country: "",
      origin_state: "",
      seed_quantity: "",
      climate_grown: "",
      fertilizers_used: "",
      soil_type: "",
      sowing: "",
      grid_no: "",
      sowed_soil: "",
      soil_water: "",
      seed_damage: "",
      image_file_name: "",
      seed_no: "", // Clear seed number
    });
    setShowSowingFields(false);
    setSelectedFile(null);
  };
  const getSpeciesOptions = () => {
    switch (formData.seed_name) {
      case "Rice":
        return [
          <MenuItem key="Oryza Sativa" value="Oryza Sativa">
            Oryza Sativa
          </MenuItem>,
          <MenuItem key="Oryza rufipogon" value="Oryza rufipogon">
            Oryza rufipogon
          </MenuItem>,
        ];
      case "Tomato":
        return [
          <MenuItem
            key="Solanum pimpinellifolium"
            value="Solanum pimpinellifolium"
          >
            Solanum pimpinellifolium
          </MenuItem>,
          <MenuItem key="Solanum lycopersicum" value="Solanum lycopersicum">
            Solanum lycopersicum
          </MenuItem>,
        ];
      case "Sorghum":
      case "Groundnut":
      case "Pigeonpea":
      case "Chickpea":
      case "Cowpea":
        return [
          <MenuItem key="Legumes" value="Legumes">
            Legumes
          </MenuItem>,
        ];
      case "Barnyard Millet":
      case "Proso Millet":
      case "Kodo Millet":
      case "Little Millet":
      case "Foxtail Millet":
      case "Finger Millet":
      case "Pearl Millet":
        return [
          <MenuItem key="Millet" value="Millet">
            Millet
          </MenuItem>,
        ];
      case "Chilli": // Adding species for Chilli
        return [
          <MenuItem key="Capsicum annuum" value="Capsicum annuum">
            Capsicum annuum
          </MenuItem>,
          <MenuItem key="Capsicum frutescens" value="Capsicum frutescens">
            Capsicum frutescens
          </MenuItem>,
        ];
      default:
        return [];
    }
  };

  const handleAdditionalImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 4) {
      alert("You can upload a maximum of 4 images.");
      return;
    }
    setAdditionalImages([...files]); // Store all selected files
  };

  const uploadAdditionalImages = async () => {
    if (!additionalImages.length) return;

    const apiKey = "vRtW4LOFB61VjXvcvRtKu6QOItTs7b7w4d2QoI2n";
    for (const file of additionalImages) {
      const imageApiUrl = `https://mexqi5uelf.execute-api.ap-south-1.amazonaws.com/dev/digi-s3-bucket%2ftraining%2f${encodeURIComponent(
        formData.seed_name
      )}%2f${encodeURIComponent(formData.seed_no)}/${encodeURIComponent(
        file.name
      )}`;
      try {
        const response = await fetch(imageApiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
            "x-api-key": apiKey,
          },
          body: file,
        });
        if (!response.ok) throw new Error("Failed to upload image");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="md">
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Seed Acquisition Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl fullWidth required>
                  <InputLabel>Seed Name</InputLabel>
                  <Select
                    name="seed_name"
                    value={formData.seed_name}
                    onChange={handleChange}
                    label="Seed Name"
                    sx={{ width: "60%", height: "40px" }}
                  >
                    <MenuItem value="Rice">Rice</MenuItem>
                    <MenuItem value="Tomato">Tomato</MenuItem>
                    <MenuItem value="Chilli">Chilli</MenuItem>
                    <MenuItem value="Chickpea">Chickpea</MenuItem>
                    <MenuItem value="Cowpea">Cowpea</MenuItem>
                    <MenuItem value="Pigeonpea">Pigeonpea</MenuItem>
                    <MenuItem value="Sorghum">Sorghum</MenuItem>
                    <MenuItem value="Groundnut">Groundnut</MenuItem>
                    <MenuItem value="Pearl Millet">Pearl Millet</MenuItem>
                    <MenuItem value="Finger Millet">Finger Millet</MenuItem>
                    <MenuItem value="Foxtail Millet">Foxtail Millet</MenuItem>
                    <MenuItem value="Little Millet">Little Millet</MenuItem>
                    <MenuItem value="Kodo Millet">Kodo Millet</MenuItem>
                    <MenuItem value="Proso Millet">Proso Millet</MenuItem>
                    <MenuItem value="Barnyard Millet">Barnyard Millet</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Species Name */}
              <Grid item xs={6}>
                <FormControl fullWidth required>
                  <InputLabel>Species Name</InputLabel>
                  <Select
                    name="species_name"
                    value={formData.species_name}
                    onChange={handleChange}
                    label="Species Name"
                    sx={{ width: "50%", height: "40px" }}
                  >
                    {getSpeciesOptions()}
                  </Select>
                </FormControl>
              </Grid>

              {/* Origin Country */}
              <Grid item xs={5}>
                <FormControl fullWidth required>
                  <InputLabel>Origin Country</InputLabel>
                  <Select
                    name="origin_country"
                    value={formData.origin_country}
                    onChange={handleChange}
                    label="Origin Country"
                    sx={{ width: "60%", height: "40px" }}
                  >
                    <MenuItem value="India">India</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Origin State */}
              <Grid item xs={5}>
                <FormControl fullWidth required>
                  <InputLabel>Origin State</InputLabel>
                  <Select
                    name="origin_state"
                    value={formData.origin_state}
                    onChange={handleChange}
                    label="Origin State"
                    sx={{ width: "60%", height: "40px" }}
                  >
                    <MenuItem value="Karnataka">Karnataka</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Acquisition Date */}
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  required
                  label="Acquisition Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  name="acquisition_date"
                  value={formData.acquisition_date}
                  onChange={handleChange}
                  sx={{ width: "60%", height: "40px" }}
                />
              </Grid>

              {/* Seed Quantity */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  label="Seed Quantity (gms)"
                  type="number"
                  name="seed_quantity"
                  value={formData.seed_quantity}
                  onChange={handleChange}
                  sx={{ width: "50%", height: "40px" }}
                />
              </Grid>

              {/* Climate Grown */}
              <Grid item xs={5}>
                <FormControl fullWidth required>
                  <InputLabel>Climate Grown</InputLabel>
                  <Select
                    name="climate_grown"
                    value={formData.climate_grown}
                    onChange={handleChange}
                    label="Climate Grown"
                    sx={{ width: "60%", height: "40px" }}
                  >
                    <MenuItem value="Tropical">Tropical</MenuItem>
                    <MenuItem value="Subtropical">Subtropical</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Fertilizers Used */}
              <Grid item xs={6}>
                <FormControl fullWidth required>
                  <InputLabel>Fertilizers Used</InputLabel>
                  <Select
                    name="fertilizers_used"
                    value={formData.fertilizers_used}
                    onChange={handleChange}
                    label="Fertilizers Used"
                    sx={{ width: "50%", height: "40px" }}
                  >
                    <MenuItem value="Organic">Organic</MenuItem>
                    <MenuItem value="Synthetic">Synthetic</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Soil Type */}
              <Grid item xs={6}>
                <FormControl fullWidth required>
                  <InputLabel>Soil Type</InputLabel>
                  <Select
                    name="soil_type"
                    value={formData.soil_type}
                    onChange={handleChange}
                    label="Soil Type"
                    sx={{ width: "50%", height: "40px" }}
                  >
                    <MenuItem value="Sandy">Sandy</MenuItem>
                    <MenuItem value="Clay">Clay</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Generate Seed Number Button */}
              <Grid item xs={8}>
                <MDButton
                  variant="contained"
                  color="success"
                  onClick={generateSeedNumber}
                >
                  Generate Seed Number
                </MDButton>
              </Grid>

              {/* Conditionally Displayed Seed Number Input */}
              {formData.seed_no && (
                <Grid item xs={6}>
                  <TextField
                    label="Seed Number"
                    name="seed_no"
                    value={formData.seed_no}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              )}

              {/* Sowing Section */}
              <Grid item xs={12} mb={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Are you sowing?</FormLabel>
                  <RadioGroup
                    row
                    name="sowing"
                    value={formData.sowing}
                    onChange={handleSowingChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Conditional Sowing Fields */}
              {showSowingFields && (
                <>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      required
                      label="Grid No"
                      name="grid_no"
                      value={formData.grid_no}
                      onChange={handleChange}
                      sx={{ width: "60%", height: "40px" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      label="Sowed Soil"
                      name="sowed_soil"
                      InputProps={{ readOnly: true }}
                      value={formData.sowed_soil}
                      onChange={handleChange}
                      sx={{ width: "50%", height: "40px" }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth required>
                      <InputLabel>Soil Water</InputLabel>
                      <Select
                        name="soil_water"
                        value={formData.soil_water}
                        onChange={handleChange}
                        label="Soil Water"
                        sx={{ width: "60%", height: "40px" }}
                      >
                        <MenuItem value="2">2ml</MenuItem>
                        <MenuItem value="4">4ml</MenuItem>
                        <MenuItem value="8">8ml</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} mb={5}>
                    <FormControl fullWidth required>
                      <InputLabel>Seed Damage</InputLabel>
                      <Select
                        name="seed_damage"
                        value={formData.seed_damage}
                        onChange={handleChange}
                        label="Seed Damage"
                        sx={{ width: "50%", height: "40px" }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="seedImage" sx={{ mt: 2 }}>
                  Upload Image
                </InputLabel>

                {/* Button to trigger the dialog */}
                <Button
                  variant="contained"
                  onClick={handleOpenDialog}
                  sx={{
                    backgroundColor: "blue", // Set background color to blue
                    color: "white", // Set text color to white
                    "&:hover": {
                      // Hover effect
                      backgroundColor: "darkblue",
                    },
                  }}
                >
                  Choose Image
                </Button>
              </Grid>

              {/* Image Preview if a file is selected */}
              {imagePreview && (
                <Grid item xs={12}>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      marginTop: "16px",
                    }}
                  />
                </Grid>
              )}

              {/* Dialog for selecting gallery or camera */}
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Select Image Source</DialogTitle>
                <DialogContent>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleOptionSelect("gallery");
                      handleCloseDialog();
                    }}
                    sx={{
                      marginBottom: 2,
                      backgroundColor: "blue", // Set background color to blue
                      color: "white", // Set text color to white
                      "&:hover": {
                        // Hover effect
                        backgroundColor: "darkblue",
                      },
                    }}
                  >
                    From Gallery
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleOptionSelect("camera");
                      handleCloseDialog();
                    }}
                    sx={{
                      backgroundColor: "blue", // Set background color to blue
                      color: "white", // Set text color to white
                      "&:hover": {
                        // Hover effect
                        backgroundColor: "darkblue",
                      },
                    }}
                  >
                    Use Camera
                  </Button>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={handleCloseDialog}
                    sx={{
                      backgroundColor: "gray", // Background color for cancel button
                      color: "white", // Text color for cancel button
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Hidden file input for gallery */}
              {selectedOption === "gallery" && (
                <input
                  id="seedImageGallery"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{
                    display: "block",
                    marginBottom: "16px",
                    width: "100%",
                    padding: "8px",
                    fontSize: "14px",
                    color: "#333",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    transition: "border-color 0.3s",
                  }}
                />
              )}

              {/* Hidden file input for camera */}
              {selectedOption === "camera" && (
                <input
                  id="seedImageCamera"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  onChange={handleFileChange}
                  style={{
                    display: "block",
                    marginBottom: "16px",
                    width: "100%",
                    padding: "8px",
                    fontSize: "14px",
                    color: "#333",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    transition: "border-color 0.3s",
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Do you want to upload images for training?
                </FormLabel>
                <RadioGroup
                  row
                  name="allowAdditionalUploads"
                  value={allowAdditionalUploads ? "Yes" : "No"}
                  onChange={(e) =>
                    setAllowAdditionalUploads(e.target.value === "Yes")
                  }
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {allowAdditionalUploads && (
              <Grid item xs={12}>
                <InputLabel htmlFor="additionalImages">
                  Upload Additional Images (Max: 4)
                </InputLabel>
                <input
                  type="file"
                  id="additionalImages"
                  accept="image/*"
                  multiple
                  onChange={handleAdditionalImageChange}
                  style={{ display: "block", marginTop: "8px" }}
                />
                {additionalImages.length > 0 && (
                  <Typography variant="body2">
                    {additionalImages.length} image(s) selected.
                  </Typography>
                )}
              </Grid>
            )}

            <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
              <MDButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                sx={{ height: "48px" }}
              >
                Submit
              </MDButton>
              <MDButton
                variant="outlined"
                color="secondary"
                onClick={clearForm}
                sx={{ height: "48px" }}
              >
                Clear
              </MDButton>
              {loading && (
                <CircularProgress
                  sx={{ display: "block", margin: "0", alignSelf: "center" }}
                />
              )}
            </Box>
          </form>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default SeedAcquisitionForm;