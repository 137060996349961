// import React from 'react';
// import PropTypes from 'prop-types';
// import { Card, CardContent, Grid } from '@mui/material';
// import MDTypography from 'components/MDTypography';
// import MDBox from 'components/MDBox';
// import './ImageStatisticsCard.css'; 
// import { useNavigate } from 'react-router-dom';

// const ImageStatisticsCard = ({ image, title, count, percentage }) => {
//   // const navigate = useNavigate();

//   // const navigatetoUpload = () => {
//   //   navigate('/upload', { state: { grainType: title.toLowerCase() } });
//   // };

//   return (
//     <Card className="image-statistics-card" >
//       <CardContent>
//         <Grid container spacing={2} alignItems="center">
//           <Grid item>
//             <img src={image} alt={title} className="card-image" />
//           </Grid>
//           <Grid item xs>
//             <MDTypography variant="h9" gutterBottom>
//               {title}
//             </MDTypography>
//             <MDTypography variant="h4" color="textPrimary">
//               {count}
//             </MDTypography>
//             {percentage && ( 
//               <MDBox display="flex" flexDirection="column" alignItems="center">
//                 {percentage.color && ( 
//                   <MDTypography variant="body2" color={percentage.color}>
//                     {percentage.amount}
//                   </MDTypography>
//                 )}
//                 {percentage.label && ( 
//                   <MDTypography variant="body2" color="textSecondary">
//                     {percentage.label}
//                   </MDTypography>
//                 )}
//               </MDBox>
//             )}
//           </Grid>
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// };

// ImageStatisticsCard.propTypes = {
//   image: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   count: PropTypes.string.isRequired,
//   percentage: PropTypes.shape({
//     color: PropTypes.string,
//     amount: PropTypes.string,
//     label: PropTypes.string,
//   }),
// };

// export default ImageStatisticsCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import './ImageStatisticsCard.css';

const ImageStatisticsCard = ({ image, title, count, percentage, onClick }) => {
  return (
    <Card
      className="image-statistics-card"
      onClick={onClick} // Attach the onClick handler
      sx={{ cursor: 'pointer' }} // Add pointer cursor for better UX
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img src={image} alt={title} className="card-image" />
          </Grid>
          <Grid item xs>
            <MDTypography variant="h9" gutterBottom>
              {title}
            </MDTypography>
            <MDTypography variant="h4" color="textPrimary">
              {count}
            </MDTypography>
            {percentage && (
              <MDBox display="flex" flexDirection="column" alignItems="center">
                {percentage.color && (
                  <MDTypography variant="body2" color={percentage.color}>
                    {percentage.amount}
                  </MDTypography>
                )}
                {percentage.label && (
                  <MDTypography variant="body2" color="textSecondary">
                    {percentage.label}
                  </MDTypography>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ImageStatisticsCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.string,
    amount: PropTypes.string,
    label: PropTypes.string,
  }),
  onClick: PropTypes.func, // Add the onClick prop type
};

export default ImageStatisticsCard;
