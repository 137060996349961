import React, { useState } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import b1 from "../assets/images/bg1.jpg";
import b2 from "../assets/images/declan-sun-ovR8zX_rk6I-unsplash.jpg";
import b3 from "../assets/images/image-classification.jpg";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
  mainContent: { flexGrow: 1, padding: 0 },
  section: {
    display: "flex",
    flexDirection: "column", // Stacks image and content vertically on small screens
    cursor: "pointer",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row", // Aligns image and content side by side on larger screens
      alignItems: "center", // Vertically aligns content next to the image
      justifyContent: "space-between", // Ensures space between image and content
    },
  },
  sectionImage: {
    width: "100%", // Use full width for better responsiveness
    height: 150,
    objectFit: "cover",
    [theme.breakpoints.up("sm")]: {
      width: "60%", // Adjust width for larger screens
      height: 200, // Set a taller height for larger screens
    },
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    boxShadow: 24,
    padding: theme.spacing(4),
  },
  customList: {
    padding: 0,
    listStyleType: "disc",
    marginLeft: 0,
    "& li": {
      marginBottom: theme.spacing(1),
    },
  },
  cardContent: {
    textAlign: "left",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center", // Center content vertically
    width: "60%", // Adjust width to match the image
  },
  centerText: {
    textAlign: "center", // Centers the text
    width: "100%", // Ensures it takes full width for centering
  },
}));

function SeedManagementPlatform() {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const navigateTo = (page) => navigate(page);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={classes.root}>
        <main className={classes.mainContent}>
          <Container>
            {/* <Box sx={{ textAlign: "center", my: 4 }}>
              <Typography variant="h4">SeedzHub</Typography>
            </Box> */}

            {/* Seed Acquisition Section */}
            <Card
              className={classes.section}
              component="a"
              onClick={() => navigateTo("/aquisition")}
              sx={{ marginTop: "25px" }}
            >
              <CardMedia
                className={classes.sectionImage}
                image={b1} // Ensure this path is correct
                title="Seed Acquisition"
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.centerText}
                >
                  Seed Acquisition
                </Typography>
                <ul
                  className={classes.customList}
                  style={{ textAlign: "left" }}
                >
                  <li>
                    Ensures accurate tracking and documentation of seed origin,
                    variety, and quantity.
                  </li>
                  <li>
                    Involves careful management of seed inventory for future
                    research or distribution.
                  </li>
                  <li>
                    Data is stored systematically to streamline the seed
                    management process.
                  </li>
                </ul>
              </CardContent>
            </Card>

            {/* Seed Characterization Section */}
            <Card
              className={classes.section}
              component="a"
              href="/characterization"
            >
              <CardMedia
                className={classes.sectionImage}
                image={b2} // Confirm the path
                title="Seed Characterization"
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.centerText}
                >
                  Seed Characterization
                </Typography>

                <ul
                  className={classes.customList}
                  style={{ textAlign: "left" }}
                >
                  <li>
                    Focuses on identifying key physical and genetic traits of
                    seeds.
                  </li>
                  <li>
                    Helps in determining seed viability and suitability for
                    specific environments.
                  </li>
                  <li>
                    Characterization includes data on size, color, texture, and
                    genetic diversity.
                  </li>
                  <li>
                    Plays a crucial role in selecting the best seeds for crop
                    improvement programs.
                  </li>
                </ul>
              </CardContent>
            </Card>

            {/* Seed Classification Section */}
            <Card
              className={classes.section}
              component="a"
              href="/classification"
            >
              <CardMedia
                className={classes.sectionImage}
                image={b3}
                title="Seed Classification"
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.centerText}
                >
                  Seed Classification
                </Typography>
                <ul
                  className={classes.customList}
                  style={{ textAlign: "left" }}
                >
                  <li>
                    Classify the seed variety using advanced ML algorithms.
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Container>
        </main>
      </div>
    </DashboardLayout>
  );
}

export default SeedManagementPlatform;
