import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage from "../../assets/images/DB.png";
import MDButton from "components/MDButton";
import bg from "../../assets/images/seed_trial_1-removebg-preview.png";

const SeedzhubLandingPage = () => {
  const navigate = useNavigate();

  // Carousel settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        px: 2,
        backgroundColor: "#f8f9fa",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Slight white overlay for readability
          borderRadius: "16px",
          py: 6,
          px: 4,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Logo Section */}
        <Box>
        <Box
  component="img"
  src={bg} // Replace with your logo path
  alt="Seedzhub Logo"
  sx={{
    width: { xs: "80%", sm: "60%", md: "40%" }, // Adjusts logo size for different screen sizes
    mx: "auto",
  }}
/>

        </Box>
        {/* Title */}
        <Typography variant="h3" component="h1" gutterBottom>
          Welcome to SeedzHub: Pioneering the Seed-to-Sapling Revolution
        </Typography>

        {/* Carousel Section */}
        <Box sx={{ mb: 6 }}>
          <Slider {...sliderSettings}>
            <Box>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Join the Movement
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  SeedzHub is a cutting-edge platform designed to revolutionize
                  the seed-to-sapling journey, blending advanced AI technology
                  with global collaboration. Whether you are a researcher,
                  farmer, or part of an agricultural organization, SeedzHub
                  empowers you to make data-driven decisions, enhance seed
                  productivity, and support sustainable agricultural practices.
                </Typography>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/login")}
                >
                  Join Now
                </MDButton>
              </Paper>
            </Box>
            <Box>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                  Partnership Opportunities
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  SeedzHub is committed to fostering partnerships with global
                  research institutions and non-profit organizations. By
                  collaborating with us, partners can leverage:
                </Typography>
                <Typography variant="body1">
                  <strong>Shared Resources:</strong> Access data analytics and
                  co-development opportunities for mutual research benefits.
                </Typography>
                <Typography variant="body1">
                  <strong>Global Impact:</strong> Extend agricultural
                  innovations to a wider audience, ensuring a meaningful
                  contribution to food security and sustainable practices.
                </Typography>

                <Typography variant="body1">
                  <strong>Customized Solutions:</strong> Tailor our tools and
                  services to align with specific research and development
                  needs.
                </Typography>

                <MDButton variant="contained" color="secondary">
                  Partner Now
                </MDButton>
              </Paper>
            </Box>
          </Slider>
        </Box>

        {/* Content Sections */}
        <Typography variant="h4" gutterBottom>
          Transforming the Future of Agriculture with AI and Community-Driven
          Insights
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          SeedzHub is a cutting-edge platform designed to revolutionize the
          seed-to-sapling journey, blending advanced AI technology with global
          collaboration. Whether you are a researcher, farmer, or part of an
          agricultural organization, SeedzHub empowers you to make data-driven
          decisions, enhance seed productivity, and support sustainable
          agricultural practices.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Our Mission
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          SeedzHub is dedicated to advancing agricultural productivity and sustainability by
          providing a digital ecosystem where data, technology, and community work hand-in-hand.
          Our mission is aligned with the United Nations Sustainable Development Goals (SDGs),
          specifically No Poverty and No Hunger, ensuring that our solutions contribute to global
          efforts in eradicating poverty and improving food security.
        </Typography>

        <Typography variant="h4" gutterBottom>
          Our Vision
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          At SeedzHub, we envision a world where sustainable agricultural practices empower
          communities and secure the future of global food production. We strive to bridge the gap
          between technology and agriculture, fostering a collaborative ecosystem where researchers,
          farmers, and organizations come together to share knowledge and resources.
        </Typography>

        <Typography variant="h4" gutterBottom>
          Who Benefits from SeedzHub?
        </Typography>
        <Grid container spacing={4} sx={{ mb: 6 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Agricultural Researchers</Typography>
                <Typography variant="body2">
                  SeedzHub provides AI-driven tools for seed characterization, predictive modeling,
                  and real-time data analysis.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Small-Scale Farmers</Typography>
                <Typography variant="body2">
                  Empowering small-scale farmers with accessible technology, local language
                  support, and community insights.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Large-Scale Farming Enterprises</Typography>
                <Typography variant="body2">
                  Scalable tools for managing extensive data and monitoring seed-to-sapling
                  processes.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Agricultural Organizations</Typography>
                <Typography variant="body2">
                  Tools and data for sustainability initiatives and impactful community outreach.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>


        <Typography variant="h4" gutterBottom>
          A Comprehensive Solution for All
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          SeedzHub’s multi-functional platform is designed to be inclusive and
          powerful, enabling users at every level to harness technology and data
          for better agricultural outcomes. From research and development to
          practical farming solutions, SeedzHub bridges the gap between advanced
          technology and everyday farming needs.
        </Typography>
        <MDButton
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate("/get-started")}
        >
          Get Started
        </MDButton>
      </Container>
    </Box>
  );
};

export default SeedzhubLandingPage;