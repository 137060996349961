import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PieChart from "examples/Charts/PieChart"; // Import PieChart component
import { makeStyles } from "@mui/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FilterSidebar from "./FilterSideBar";


const SeedDataFetcher = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    section: {
      margin: theme.spacing(2, 0),
    },
    actionButtons: {
      display: "flex",
      gap: "10px",
    },
    modalField: {
      marginBottom: theme.spacing(2),
    },
  }));

  const [seedName, setSeedName] = useState("");
  const [seedData, setSeedData] = useState([]);
  const [filteredSeedData, setFilteredSeedData] = useState([]);
  const [selectedSeed, setSelectedSeed] = useState(null);
  const [error, setError] = useState("");
  const [chartData, setChartData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editableFields, setEditableFields] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  
  // Improved filters state with more comprehensive options
  const [filters, setFilters] = useState({
    species_name: "",
    origin_country: "",
    origin_state: "",
    climate_grown: "",
    soil_type: "",
    date_from: "",
    date_to: "",
    sowing: "",
    seed_damage: "",
  });


  const classes = useStyles();

  const handleSeedChange = (event) => {
    setSeedName(event.target.value);
    setSeedData([]);
    setFilteredSeedData([]);
    setChartData(null);
    setSelectedSeed(null);
    setError("");
  };

  const handleFieldChange = (field, value) => {
    setEditableFields((prevFields) => ({ ...prevFields, [field]: value }));
  };
  const handleSidebarClose = () => setShowSidebar(false);
  const handleSidebarShow = () => setShowSidebar(true);

  const fetchSeedData = () => {
    if (!seedName) {
      alert("Please select a Seed Name.");
      return;
    }

    const url = new URL(
      "https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/dataGet"
    );
    url.searchParams.append("seed_name", seedName);

    setLoading(true);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const fetchedData = data["body-json"].body;
        setSeedData(fetchedData || []);
        setFilteredSeedData(fetchedData || []);
        setError(fetchedData && fetchedData.length > 0 ? "" : "No data available");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleEditClick = (seed) => {
    setSelectedSeed(seed);
    setEditableFields({
      climate_grown: seed.climate_grown || "",
      soil_type: seed.soil_type || "",
      species_name: seed.species_name || "", // Add species_name here
    });
    setOpenModal(true);
  };

  const handleViewClick = (seed) => {
    setSelectedSeed(seed);
    setOpenViewModal(true);
  };
  const handleFilterChange = (id, value) => {
    setFilters(prev => ({
      ...prev,
      [id]: value
    }));
  };
  const handleClearFilters = () => {
    // Reset all filters
    setFilters({
      species_name: "",
      origin_country: "",
      origin_state: "",
      climate_grown: "",
      soil_type: "",
      date_from: "",
      date_to: "",
      sowing: "",
      seed_damage: "",
    });
    setFilteredSeedData(seedData);
    setShowSidebar(false);
  };
  

  const handleUpdate = () => {
    const payload = {
      body: JSON.stringify({
        seed_no: selectedSeed.seed_no,
        seed_name: selectedSeed.seed_name,
        update_fields: editableFields,
      }),
    };
    console.log("payload", payload);
    fetch(
      "https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/update",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("update data:", data);
        if (data["body-json"]?.statusCode === 200) {
          alert("Seed details updated successfully!");
          fetchSeedData(); // Refresh the data
          setOpenModal(false);
        } else {
          alert("Failed to update seed details.");
        }
      })
      .catch((error) => {
        console.error("Error updating seed details:", error);
        alert("An error occurred while updating seed details.");
      });
  };
    // Prepare unique filter options dynamically
    const getUniqueFilterOptions = (field) => {
      return [...new Set(seedData.map(item => item[field] || '').filter(Boolean))];
    };

  const applyFilters = () => {
    let result = [...seedData];

    // Apply each filter condition
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        result = result.filter(seed => {
          // Handle date range filters
          if (key === 'date_from' && seed.acquisition_date) {
            return new Date(seed.acquisition_date) >= new Date(filters[key]);
          }
          if (key === 'date_to' && seed.acquisition_date) {
            return new Date(seed.acquisition_date) <= new Date(filters[key]);
          }

          // Handle other filters with case-insensitive partial matching
          if (typeof seed[key] === 'string') {
            return seed[key].toLowerCase().includes(filters[key].toLowerCase());
          }

          // Exact match for other types
          return seed[key] === filters[key];
        });
      }
    });

    setFilteredSeedData(result);
    setShowSidebar(false);
  };

  const generateReport = () => {
    if (!seedName || seedData.length === 0) {
      alert("No data available for generating the report.");
      return;
    }

    const speciesCounts = seedData.reduce((acc, seed) => {
      const species = seed.species_name || "Unknown";
      acc[species] = (acc[species] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(speciesCounts);
    const data = Object.values(speciesCounts);

    setChartData({
      labels,
      datasets: {
        label: "Count",
        data,
        backgroundColors: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    });
  };


  
  const generatePDF = () => {
    if (!seedData || seedData.length === 0) {
      alert("No data available to generate the PDF. Please fetch the data first.");
      return;
    }
  
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });
  
    const reportTitle = "Seed_Report";
    const category = seedData[0]?.seed_name || 'General';
    const titleWithCategory = `${reportTitle} - Category: ${category}`;
  
    const pageWidth = doc.internal.pageSize.width;
    const textWidth = doc.getStringUnitWidth(titleWithCategory) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const x = (pageWidth - textWidth) / 2;
  
    // Add Title
    doc.text(titleWithCategory, x, 10);
  
    // Prepare Table Data
    const tableHeaders = [
      "Seed Name",
      "Species Name",
      "Origin Country",
      "Origin State",
      "Climate Grown",
      "Soil Type",
      "Sowed Soil",
      "Fertilizers Used",
      "Seed Damage",
      "Acquisition Date",
    ];
  
    const tableData = seedData.map((row) => [
      row.seed_name || "N/A",
      row.species_name || "N/A",
      row.origin_country || "N/A",
      row.origin_state || "N/A",
      row.climate_grown || "N/A",
      row.soil_type || "N/A",
      row.sowed_soil || "N/A",
      row.fertilizers_used || "N/A",
      row.seed_damage || "N/A",
      row.acquisition_date || "N/A",
    ]);
  
    // Add Table
    doc.autoTable({
      startY: 20,
      head: [tableHeaders],
      body: tableData,
      theme: "striped", // Theme can be "plain", "striped", or "grid"
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [22, 160, 133] }, // Customize header color
    });
  
    // Save the PDF
    doc.save(`${reportTitle}_${category}.pdf`);
  };
  

  // Prepare rows for DataTable using filteredSeedData
  const rows = filteredSeedData.map((seed) => ({
    seed_no: (
      <MDTypography variant="button" fontWeight="medium">
        {seed.seed_no}
      </MDTypography>
    ),
    seed_name: <MDTypography variant="button">{seed.seed_name}</MDTypography>,
    species_name: seed.species_name || "N/A",
    image: seed.image ? (
      <a href={seed.image} target="_blank" rel="noopener noreferrer">
        <img
          src={seed.image}
          alt={`Image of ${seed.species_name}`}
          style={{ width: "50px", height: "50px" }}
        />
      </a>
    ) : (
      "No Image"
    ),
    action: (
      <div className={classes.actionButtons}>
        <MDButton
          variant="contained"
          color="info"
          onClick={() => handleViewClick(seed)}
        >
          View Details
        </MDButton>
        <MDButton
          variant="contained"
          color="info"
          onClick={() => handleEditClick(seed)}
        >
          Edit
        </MDButton>
      </div>
    ),
  }));

  const columns = [
    { Header: "Seed No", accessor: "seed_no", align: "left" },
    { Header: "Seed Name", accessor: "seed_name", align: "left" },
    { Header: "Species Name", accessor: "species_name", align: "left" },
    { Header: "Image", accessor: "image", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Seed Data
        </Typography>

        <FormControl fullWidth margin="normal" size="medium">
          <InputLabel>Select Seed Name</InputLabel>
          <Select
            sx={{ width: "50%", height: "40px" }}
            value={seedName}
            onChange={handleSeedChange}
          >
            <MenuItem value="">Select Seed Name</MenuItem>
            <MenuItem value="Rice">Rice</MenuItem>
            <MenuItem value="Tomato">Tomato</MenuItem>
            <MenuItem value="Chilli">Chilli</MenuItem>
            <MenuItem value="Chickpea">Chickpea</MenuItem>
            <MenuItem value="Cowpea">Cowpea</MenuItem>
            <MenuItem value="Pigeonpea">Pigeonpea</MenuItem>
            <MenuItem value="Sorghum">Sorghum</MenuItem>
            <MenuItem value="Groundnut">Groundnut</MenuItem>
            <MenuItem value="Pearl Millet">Pearl Millet</MenuItem>
            <MenuItem value="Finger Millet">Finger Millet</MenuItem>
            <MenuItem value="Foxtail Millet">Foxtail Millet</MenuItem>
            <MenuItem value="Little Millet">Little Millet</MenuItem>
            <MenuItem value="Kodo Millet">Kodo Millet</MenuItem>
            <MenuItem value="Proso Millet">Proso Millet</MenuItem>
            <MenuItem value="Barnyard Millet">Barnyard </MenuItem>
          </Select>
        </FormControl>

        <MDButton
          variant="contained"
          color="primary"
          onClick={fetchSeedData}
          sx={{ mt: 2, mb: 3 }}
        >
          Fetch Seed Data
        </MDButton>
        <MDButton
          variant="contained"
          color="secondary"
          onClick={generateReport}
          sx={{ mt: 2, mb: 3, ml: 2 }}
        >
          Get Report
        </MDButton>
        <MDButton
          variant="contained"
          color="secondary"
          onClick={generatePDF}
          sx={{ mt: 2, mb: 3, ml: 2 }}
        >
          {" "}
          Download Report
        </MDButton>

        {seedData.length > 0 && (
            <MDButton
              variant="outlined"
              color="primary"
              onClick={() => setShowSidebar(true)}
              sx={{ mt: 2, mb: 3, ml: 2 }}
            >
              Filter Data
            </MDButton>
          )}
    <FilterSidebar
          show={showSidebar}
          handleClose={() => setShowSidebar(false)}
          filters={[
            {
              id: "species_name",
              label: "Species Name",
              type: "select",
              value: filters.species_name,
              options: getUniqueFilterOptions('species_name')
            },
            {
              id: "climate_grown",
              label: "Climate Grown",
              type: "select",
              value: filters.climate_grown,
              options: getUniqueFilterOptions('climate_grown')
            },
            {
              id: "soil_type",
              label: "Soil Type",
              type: "select",
              value: filters.soil_type,
              options: getUniqueFilterOptions('soil_type')
            },
            {
              id: "date_from",
              label: "Date From",
              type: "date",
              value: filters.date_from
            },
            {
              id: "date_to",
              label: "Date To",
              type: "date",
              value: filters.date_to
            },
            {
              id: "sowing",
              label: "Sowing",
              type: "select",
              value: filters.sowing,
              options: ["Yes", "No"]
            },
            {
              id: "seed_damage",
              label: "Seed Damage",
              type: "select",
              value: filters.seed_damage,
              options: getUniqueFilterOptions('seed_damage')
            }
          ]}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={applyFilters}
        />
        {loading && (
          <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
        )}

        {error && <Typography color="error">{error}</Typography>}

        {chartData && (
          <Box mb={3}>
            <PieChart
              title={`Species Distribution for ${seedName}`}
              description="Pie chart representing species distribution"
              chart={chartData}
              height="20rem"
            />
          </Box>
        )}
        
        {seedData.length > 0 && (
          <TableContainer component={Paper}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage
              showTotalEntries={false}
              pagination
              noEndBorder
            />
          </TableContainer>
        )}

        <Dialog
          open={openViewModal}
          onClose={() => setOpenViewModal(false)} // Close the View Modal
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>View Seed Details</DialogTitle>
          <DialogContent>
            {selectedSeed && (
              <>
                <Typography>Seed No: {selectedSeed.seed_no}</Typography>
                <Typography>Seed Name: {selectedSeed.seed_name}</Typography>
                <Typography>
                  Species Name: {selectedSeed.species_name}
                </Typography>
                <Typography>
                  Fertilizers Used: {selectedSeed.fertilizers_used || "N/A"}
                </Typography>
                <Typography>
                  Origin:{" "}
                  {`${selectedSeed.origin_state || "N/A"}, ${
                    selectedSeed.origin_country || "N/A"
                  }`}
                </Typography>
                <Typography>
                  Seed Damage: {selectedSeed.seed_damage || "N/A"}
                </Typography>
                <Typography>
                  Climate Grown: {selectedSeed.climate_grown || "N/A"}
                </Typography>
                <Typography>
                  Soil Type: {selectedSeed.soil_type || "N/A"}
                </Typography>
                <Typography>
                  Soil Water: {selectedSeed.soil_water || "N/A"}
                </Typography>
                <Typography>
                  Sowed Soil: {selectedSeed.sowed_soil || "N/A"}
                </Typography>
                <Typography>
                  Sowing: {selectedSeed.sowing ? "Yes" : "No"}
                </Typography>
                <Typography>
                  Grid no: {selectedSeed.grid_no || "N/A"}
                </Typography>
                <Typography>
                  Acquisition Date: {selectedSeed.acquisition_date || "N/A"}
                </Typography>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenViewModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Modal */}
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit Seed Details</DialogTitle>
          <DialogContent>
            {/* Read-only fields for Seed Name and Seed Number */}
            <TextField
              fullWidth
              label="Seed Number"
              value={selectedSeed?.seed_no || ""}
              InputProps={{
                readOnly: true,
              }}
              className={classes.modalField}
            />
            <TextField
              fullWidth
              label="Seed Name"
              value={selectedSeed?.seed_name || ""}
              InputProps={{
                readOnly: true,
              }}
              className={classes.modalField}
            />

            {/* Editable fields */}
            <TextField
              fullWidth
              label="Climate Grown"
              value={editableFields.climate_grown || ""}
              onChange={(e) =>
                handleFieldChange("climate_grown", e.target.value)
              }
              className={classes.modalField}
            />
            <TextField
              fullWidth
              label="Soil Type"
              value={editableFields.soil_type || ""}
              onChange={(e) => handleFieldChange("soil_type", e.target.value)}
              className={classes.modalField}
            />
            <TextField
              fullWidth
              label="Species Name"
              value={editableFields.species_name || ""}
              onChange={(e) =>
                handleFieldChange("species_name", e.target.value)
              }
              className={classes.modalField}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </DashboardLayout>
  );
};

export default SeedDataFetcher;