import React from 'react';
import {
  Drawer,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from 'components/MDButton';

const FilterSidebar = ({ 
  show, 
  handleClose, 
  filters, 
  handleFilterChange, 
  handleClearFilters, 
  handleApplyFilters 
}) => {
  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: 300,
          padding: 2
        }
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2 
        }}
      >
        <Typography variant="h6">Filters</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Box 
        sx={{ 
          overflowY: 'auto', 
          display: 'flex',
          flexDirection: 'column',
          gap: 2, 
          marginTop: 3
        }}
      >
        {filters.map(filter => (
          <FormControl 
            key={filter.id} 
            fullWidth 
            variant="outlined"
            size="small"
          >
            {filter.type === 'select' ? (
              <>
                <InputLabel>{filter.label}</InputLabel>
                <Select
                  value={filter.value}
                  label={filter.label}
                  onChange={(e) => handleFilterChange(filter.id, e.target.value)}
                  sx={{ width: "60%", height: "40px" }}
                >
                  <MenuItem value="">All</MenuItem>
                  {filter.options.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <TextField
                type={filter.type}
                label={filter.label}
                value={filter.value}
                onChange={(e) => handleFilterChange(filter.id, e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={
                  filter.type === 'date' || filter.type === 'month' 
                  ? { shrink: true } 
                  : {}
                }
              />
            )}
          </FormControl>
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          gap: 2 
        }}
      >
        <MDButton 
          variant="contained" 
          color="primary" 
          onClick={handleApplyFilters}
          fullWidth
        >
          Apply Filters
        </MDButton>
        <MDButton 
          variant="outlined" 
          color="secondary" 
          onClick={handleClearFilters}
          fullWidth
        >
          Clear Filters
        </MDButton>
      </Box>
    </Drawer>
  );
};

export default FilterSidebar;
