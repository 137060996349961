import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const SeedCharacterization = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    section: {
      margin: theme.spacing(2, 0),
    },
    actionButtons: {
      display: "flex",
      gap: "10px",
    },
    modalField: {
      marginBottom: theme.spacing(2),
    },
  }));

  const [seedData, setSeedData] = useState([]);
  const [seedOptions] = useState([
    "Chilli",
    "Tomato",
    "Pepper",
    "Rice",
    "Chickpea",
    "Pigeonpea",
    "Sorghum",
    "Groundnut",
    "Pearl Millet",
    "Finger Millet",
    "Foxtail Millet",
    "Little Millet",
    "Kodo Millet",
    "Proso Millet",
    "Barnyard Millet",
  ]);
  const [seedName, setSeedName] = useState("");
  const [characteristics, setCharacteristics] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [traitsDialogOpen, setTraitsDialogOpen] = useState(false);
  const [selectedSeed, setSelectedSeed] = useState(null);
  const [seedArea, setSeedArea] = useState("");
  const [seedLength, setSeedLength] = useState("");
  const [seedWidth, setSeedWidth] = useState("");
  const [traits, setTraits] = useState(null);
  const [seedDataDialogOpen, setSeedDataDialogOpen] = useState(false);
  const [processedData, setProcessedData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (seedName) {
      fetchSeedData(seedName);
    }
  }, [seedName]);

  const fetchSeedData = async (seedName) => {
    setLoading(true);
    const url = new URL(
      "https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/dataGet"
    );
    url.searchParams.append("seed_name", seedName);

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (Array.isArray(data["body-json"]?.body)) {
        setSeedData(data["body-json"].body);
      } else {
        setSeedData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setSeedData([]);
    } finally {
      setLoading(false);
    }
  };

  const calculateSeedData = async (seed) => {
    const url = new URL(
      "https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/CalculateTrait"
    );
    url.searchParams.append("seed_name", seed.seed_name);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          seed_no: seed.seed_no,
          seed_name: seed.species_name,
          image: seed.image,
        }),
      });

      const data = await response.json();
      const message = data["body-json"]?.body?.message;
      const traits = data["body-json"]?.body?.processed_data;
      const error = data["body-json"]?.body;


      if (traits) {
        setProcessedData(traits);
        setSeedDataDialogOpen(true);
        alert(message);

      } else {
        setProcessedData(null);
        alert(error);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch seed data. Please try again.");
      setProcessedData(null);
    }
  };

  const handleSeedDataDialogClose = () => {
    setSeedDataDialogOpen(false);
    setProcessedData(null);
  };

  const viewSeedTraits = async (seed) => {
    try {
      const url =
        `https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/getTraits?seed_no=${seed.seed_no}&seed_name=${seed.seed_name}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to fetch seed traits");

      const data = await response.json();
      setTraits(data["body-json"].body);
      setTraitsDialogOpen(true);
    } catch (error) {
      console.error("Error fetching seed traits:", error);
      alert("Failed to fetch seed traits. Please try again.");
    }
  };

  const handleEnterSeedInfo = (seed) => {
    setSelectedSeed(seed);
    setSeedArea("");
    setSeedLength("");
    setSeedWidth("");
    setInfoDialogOpen(true);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  const handleTraitsDialogClose = () => {
    setTraitsDialogOpen(false);
  };

  const handleInfoSubmit = async () => {
    const seedInfo = {
      seed_no: selectedSeed.seed_no,
      seed_name: selectedSeed.species_name,
      seed_area: seedArea,
      seed_length: seedLength,
      seed_width: seedWidth,
    };

    try {
      const response = await fetch(
        "https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/updateTrait",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(seedInfo),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit seed information");
      }

      const result = await response.json();
      console.log("Seed Information Submitted:", result);
      setInfoDialogOpen(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to submit seed information. Please try again.");
    }
  };

  const handleSeedNameChange = (event) => {
    setSeedName(event.target.value);
  };

  const classes = useStyles();

  const rows = seedData.map((seed) => ({
    seed_no: (
      <MDTypography variant="button" fontWeight="medium">
        {seed.seed_no || "N/A"}
      </MDTypography>
    ),
    species_name: (
      <MDTypography variant="button" fontWeight="medium">
        {seed.species_name || "N/A"}
      </MDTypography>
    ),
    image: seed.image ? (
      <a href={seed.image} target="_blank" rel="noopener noreferrer">
        <img
          src={seed.image}
          alt={`Image of ${seed.species_name || "seed"}`}
          style={{ width: "50px", height: "50px" }}
        />
      </a>
    ) : (
      "No Image"
    ),
    action: (
      <div className={classes.actionButtons}>
        <MDButton
          variant="contained"
          color="primary"
          onClick={() => calculateSeedData(seed)}
        >
          Calculate
        </MDButton>
        <MDButton
          variant="outlined"
          color="secondary"
          onClick={() => handleEnterSeedInfo(seed)}
        >
          Enter Trait Information
        </MDButton>
        <MDButton
          variant="outlined"
          color="info"
          onClick={() => viewSeedTraits(seed)}
        >
          View Seed Traits
        </MDButton>
      </div>
    ),
  }));

  const columns = [
    { Header: "Seed No", accessor: "seed_no", align: "left" },
    { Header: "Species Name", accessor: "species_name", align: "left" },
    { Header: "Image", accessor: "image", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={classes.root}>
        <h1>Seed Characterization</h1>

        <FormControl fullWidth variant="outlined" className={classes.section}>
          <InputLabel>Seed Name</InputLabel>
          <Select
            value={seedName}
            onChange={handleSeedNameChange}
            label="Seed Name"
            sx={{ width: "50%", height: "40px", marginBottom: "25px" }}
          >
            {loading && (
              <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
            )}
            <MenuItem value="">
              <em>Select a Seed</em>
            </MenuItem>
            {seedOptions.map((seed, index) => (
              <MenuItem key={index} value={seed}>
                {seed}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container spacing={2}>
          <TableContainer component={Paper}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={true}
              showTotalEntries={false}
              pagination={true}
              noEndBorder
            />
          </TableContainer>
        </Grid>
{/* Seed Data Dialog */}
<Dialog open={seedDataDialogOpen} onClose={handleSeedDataDialogClose}>
        <DialogTitle>Seed Processed Data</DialogTitle>
        <DialogContent>
          {processedData ? (
            <div>
              <Typography variant="body1">Area (cm²): {processedData.area_cm}</Typography>
              <Typography variant="body1">Length (cm): {processedData.length_cm}</Typography>
              <Typography variant="body1">Width (cm): {processedData.width_cm}</Typography>
            </div>
          ) : (
            <Typography variant="body1">No processed data available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleSeedDataDialogClose} color="primary">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
        <Dialog open={infoDialogOpen} onClose={handleInfoDialogClose}>
          <DialogTitle>Enter Seed Information</DialogTitle>
          <DialogContent>
            <TextField
              label="Seed No"
              value={selectedSeed ? selectedSeed.seed_no : ""}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
              className={classes.modalField}
            />
            <TextField
              label="Seed Name"
              value={selectedSeed ? selectedSeed.species_name : ""}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
              className={classes.modalField}
            />
            <TextField
              label="Seed Area"
              value={seedArea}
              onChange={(e) => setSeedArea(e.target.value)}
              fullWidth
              margin="normal"
              className={classes.modalField}
            />
            <TextField
              label="Seed Length"
              value={seedLength}
              onChange={(e) => setSeedLength(e.target.value)}
              fullWidth
              margin="normal"
              className={classes.modalField}
            />
            <TextField
              label="Seed Width"
              value={seedWidth}
              onChange={(e) => setSeedWidth(e.target.value)}
              fullWidth
              margin="normal"
              className={classes.modalField}
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleInfoDialogClose} color="secondary">
              Cancel
            </MDButton>
            <MDButton onClick={handleInfoSubmit} color="primary">
              Submit
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog open={traitsDialogOpen} onClose={handleTraitsDialogClose}>
          <DialogTitle>Seed Traits</DialogTitle>
          <DialogContent>
            <pre>
              {traits ? JSON.stringify(traits, null, 2) : "Loading traits..."}
            </pre>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleTraitsDialogClose} color="primary">
              Close
            </MDButton>
          </DialogActions>
        </Dialog>
      </div>
    </DashboardLayout>
  );
};

export default SeedCharacterization;