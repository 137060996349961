import Icon from "@mui/material/Icon";
import Basic from "layouts/authentication/sign-in";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SeedAcquisitionForm from "layouts/aquisition/aquisition";
import SeedAcquisitionFormChilli from "layouts/aquisition/aquisitionChilli";
import SeedCharacterization from "layouts/characterization/characterization";
import SeedClassification from "layouts/classification/classification";
import SeedDataFetcher from "layouts/SeedData/GetSeedData";
import SeedManagementPlatform from "layouts/landing";
import LandingPage from "layouts/landingpage/landingpage";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <SeedManagementPlatform />,
    protected: false,
  },

  {
    type: "collapse",
    name: "Aquisition Data",
    key: "AquisitionData",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/AquisitionData",
    component: <SeedDataFetcher />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/aquisition",
    component: <SeedAcquisitionForm />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/aquisition-chilli",
    component: <SeedAcquisitionFormChilli />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/characterization",
    component: <SeedCharacterization />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/classification",
    component: <SeedClassification />,
    protected: false,
  },
  {
    // type: "collapse",
    name: "Login",
    key: "Login",
    icon: <LockOpenIcon icon={LockOpenIcon} />,
    route: "/Login",
    component: <Basic />,
    protected: false,
  },
  {
    // type: "collapse",
    name: "Landing-Page",
    key: "Landing-Page",
    icon: <LockOpenIcon icon={LockOpenIcon} />,
    route: "/landingpage",
    component: <LandingPage />,
    protected: false,
  },

];

export default routes;
