import React from "react";
import { Box, Container, Grid, Button, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DB from "../../../assets/images/BannerImage1.png";
import DB1 from "../../../assets/images/seedzhub-logo1.png";
import "./sign-in.css";
import MDButton from "components/MDButton";

const Basic = () => {
  const handleLoginClick = () => {

    window.location.href =
      "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://portal.seedzhub.com/home";
    // "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/home";
  };
  const handlePhLoginClick = () => {
    window.location.href =
    "https://ap-south-1iqljqonee.auth.ap-south-1.amazoncognito.com/login?client_id=262t8nukf1l9ricghbab8osaup&response_type=token&scope=email+openid+phone&redirect_uri=https://portal.seedzhub.com/home"    
    // "https://ap-south-1iqljqonee.auth.ap-south-1.amazoncognito.com/login?client_id=262t8nukf1l9ricghbab8osaup&response_type=token&scope=email+openid+phone&redirect_uri=http://localhost:3000/home"    
  };

  return (
    <>
          <img
              src={DB1}
              alt="Logo"
              style={{
                width: "100%",
                maxWidth: "300px",
                height: "auto",
                marginBottom:"30px"
              }}
            />
      <div
        className="background"
        style={{
          backgroundImage: `url(${DB})`,
          width: "100%",
          height: "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></div>
        <div className="signin-dashboard-container dz">
          
      {/* Container for 

      and title */}

      <Container sx={{ position: "relative", zIndex: 1 }}>

        <Box
          sx={{
            py: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: { xs: 5, sm: 4, md: 6 }, // Responsive margin to prevent overlap
          }}
        >

<Typography
  variant="h1"
  align="center"
  sx={{
    fontSize: { xs: "3rem", sm: "5rem" },
    fontFamily: "Monospace",
  }}
>
  <span style={{ color: "#487800" }}>Welcome To</span>{" "}
  <span style={{ color: "inherit" }}>SeedzHub</span>
</Typography>

         
        </Box>
      </Container>

      <MDBox
        sx={{
          minHeight: "20vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Grid spacing={3} justifyContent="center" alignItems="center">
            {/* Reduced width of the Grid item */}
            <Grid item xs={12} sm={6} md={4} lg={3} textAlign="center">
              <Typography variant="body1" color="black" gutterBottom>
               <b>Login To Explore More</b> 
              </Typography>
              <MDButton
                variant="contained"
                color="info"
                size="medium"
                onClick={handlePhLoginClick}
                startIcon={<i className="bi bi-person-circle" />}
                sx={{margin:"5px"}}
              >
                <Typography variant="h6" color="#ffffed" align="center">
                  Login
                </Typography>
              </MDButton>
            </Grid>
          </Grid>
        </Container>
      </MDBox> </div>
    </>
  );
};

export default Basic;